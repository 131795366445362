import useMyCapitechClientSettings from 'hooks/useMyCapitechClientSettings';
import { useSelectedTimePeriod } from 'features/dashboard/ctx/SelectedTimePeriodCtx';
import useTimeRegistrationAccess from 'features/dashboard/hooks/useTimeRegistrationAccess';
import { dateToIsoString } from 'utils/datetime';
import { UseHasQuickAddResult } from './useHasQuickAdd.types';
import useEmployeeRegistrationInformation from '../useEmployeeRegistrationInformation';

/**
 * Not to be confused with quick add on duties from Flow Plan, this hook checks if the user has access to quick add.
 * It does, however, respect the same setting in MyCapitechClientSettings.
 * @returns {UseHasQuickAddResult}
 */
export default function useHasQuickAdd(): UseHasQuickAddResult {
    const { employeeRegistrationInformation } = useEmployeeRegistrationInformation('');
    const { myCapitechClientSettings } = useMyCapitechClientSettings();
    const { selectedDate } = useSelectedTimePeriod();
    const dateInIsoFormat = dateToIsoString(selectedDate || new Date());
    const { isTimeRegistrationAccessible } = useTimeRegistrationAccess(dateInIsoFormat, true);

    if (!selectedDate) {
        return false;
    }

    if (!isTimeRegistrationAccessible) {
        return false;
    }

    if (!employeeRegistrationInformation) {
        return false;
    }

    if (
        employeeRegistrationInformation.hasCapitechPlan ||
        employeeRegistrationInformation.hasCapitechFlowPlan
    ) {
        return false;
    }

    if (!myCapitechClientSettings?.isQuickAddVisible) {
        return false;
    }

    if (!employeeRegistrationInformation.defaultWorkingHours) {
        return false;
    }

    return true;
}
