import { DateEventType, SubsetMetaData } from 'components/Calendar/DateEvent';
import {
    DateEventGroupType,
    DateEventSet,
    DateEventSetsByDate
} from 'components/Calendar/DateEventGroupWrapper';
import { useTimeTransactionsState } from 'features/dashboard/ctx/TimeTransactionsProvider';
import dateI18n from 'i18n/dateI18n';
import { fill, groupBy, map, mapValues, partition, sortBy, times } from 'lodash';
import IAbsenceTransactionResult from 'models/AbsenceTransactionResult';
import { ITimeTransactionDetailResult } from 'models/TimeTransactionDetailResult';
import { useMemo } from 'react';
import useHolidays from 'features/dashboard/hooks/useHolidays/useHolidays';
import { Holiday } from 'models/Holiday';
import AbsenceRequest from 'models/AbsenceRequest';
import { dateToIsoString } from 'utils/datetime';
import { useAbsenceRequestsAffectingDateRange } from 'features/absence/providers';
import { useSelectedTimePeriod } from 'features/dashboard/ctx/SelectedTimePeriodCtx';
import { useCapitechDataVisibility } from 'features/dashboard/providers';
import { capitechDataVisuals as dataVisuals } from 'features/dashboard/util';
import { CapitechDataName } from 'features/dashboard/util/capitechData';
import usePrevious from 'utils/usePrevious';
import { useDutiesInSetDateRange } from 'features/plan';
import { DutyAvailable, DutyPlanned, DutyRequested, DutyWish, DutyWishPeriod } from 'models/Duty';
import { i18n } from 'i18n';
import useAbsenceTransactions from '../../../hooks/useAbsenceTransactions';

type CalendarData =
    | ITimeTransactionDetailResult
    | IAbsenceTransactionResult
    | Holiday
    | AbsenceRequest
    | DutyPlanned
    | DutyAvailable
    | DutyRequested
    | DutyWish
    | DutyWishPeriod;

type CapitechDateEventType = DateEventType<CapitechDataName, CalendarData>;
type CapitechDateEventGroupType = DateEventGroupType<CapitechDataName, CalendarData>;
type CapitechDateEventSet = DateEventSet<CapitechDataName, CalendarData>;

type CapitechDateEventSetsByDate = DateEventSetsByDate<CapitechDataName, CalendarData>;

function convertTimeTransactionDetailToDateEvent(
    detail: ITimeTransactionDetailResult
): CapitechDateEventType {
    const isComplete = detail.timeIn && detail.timeOut;
    const transactionDateType = isComplete ? 'time' : 'timeIncomplete';
    const transactionVisuals = dataVisuals[transactionDateType];
    const titleTranslationKey = isComplete
        ? 'calendar.hourRegistration'
        : 'calendar.hourRegistrationIncomplete';

    return {
        type: isComplete ? 'time' : 'timeIncomplete',
        color: transactionVisuals.color,
        descripiton: detail.transactionDescriptions,
        fillVariant: transactionVisuals.fillVariant,
        timeStart: detail.timeIn,
        timeStop: detail.timeOut,
        sourceData: detail,
        dateString: detail.dateIn,
        timeStartAsDate:
            detail.dateIn && detail.timeIn
                ? dateI18n(`${detail.dateIn} ${detail.timeIn}`, 'YYYY-MM-DD HH:mm').toDate()
                : null,
        timeStopAsDate:
            detail.dateOut && detail.timeOut
                ? dateI18n(`${detail.dateOut} ${detail.timeOut}`, 'YYYY-MM-DD HH:mm').toDate()
                : null,
        titleFromTranslationKey: titleTranslationKey
    };
}

function convertAbsenceTransactionToDateEvent(
    absenceTransaction: IAbsenceTransactionResult
): CapitechDateEventType {
    return {
        type: 'absence',
        color: dataVisuals.absence.color,
        descripiton: absenceTransaction.absenceCodeDescription,
        fillVariant: dataVisuals.absence.fillVariant,
        timeStart: absenceTransaction.timeFrom,
        timeStop: absenceTransaction.timeTo,
        sourceData: absenceTransaction,
        dateString: absenceTransaction.dateFrom,
        timeStartAsDate:
            absenceTransaction.dateFrom && absenceTransaction.timeFrom
                ? dateI18n(
                      `${absenceTransaction.dateFrom} ${absenceTransaction.timeFrom}`,
                      'YYYY-MM-DD HH:mm'
                  ).toDate()
                : null,
        timeStopAsDate:
            absenceTransaction.dateTo && absenceTransaction.timeTo
                ? dateI18n(
                      `${absenceTransaction.dateTo} ${absenceTransaction.timeTo}`,
                      'YYYY-MM-DD HH:mm'
                  ).toDate()
                : null,
        titleFromTranslationKey: 'calendar.hourRegistration'
    };
}

function convertAbsenceRequestIntoDateEvents(
    absenceRequest: AbsenceRequest
): Array<CapitechDateEventType> {
    const absenceFromDate = dateI18n(absenceRequest.fromDate);
    const absenceToDate = dateI18n(absenceRequest.toDate);
    const absenceLength = absenceToDate.diff(absenceFromDate, 'day') + 1;

    const daySlots = times(absenceLength);
    const daySlotsWithAbsenceToConvert = fill<AbsenceRequest>(daySlots, absenceRequest);

    // Date calculations we know for sure will be the same for all days.
    const timeStartAsDate =
        absenceRequest.fromDate && absenceRequest.fromTime
            ? dateI18n(
                  `${absenceRequest.fromDate} ${absenceRequest.fromTime}`,
                  'YYYY-MM-DD HH:mm'
              ).toDate()
            : null;

    const timeStopAsDate =
        absenceRequest.toDate && absenceRequest.toTime
            ? dateI18n(
                  `${absenceRequest.toDate} ${absenceRequest.toTime}`,
                  'YYYY-MM-DD HH:mm'
              ).toDate()
            : null;
    const isWholeDay = !absenceRequest.fromTime && !absenceRequest.toTime;

    return daySlotsWithAbsenceToConvert.map<CapitechDateEventType>(
        (absenceRequestToConvert, index) => ({
            type: 'absenceRequest',
            color: dataVisuals.absenceRequest.color,
            descripiton: absenceRequest.absenceCodeDescription,
            fillVariant: dataVisuals.absenceRequest.fillVariant,
            sourceData: absenceRequestToConvert,
            dateString: dateToIsoString(absenceFromDate.add(index, 'day').toDate()),
            timeStartAsDate,
            timeStopAsDate,
            timeStart: absenceRequestToConvert.fromTime || undefined,
            timeStop: absenceRequestToConvert.toTime || undefined,
            titleFromTranslationKey: 'calendar.absenceRequest',
            isHoliday: false,
            isWholeDay
        })
    );
}

function convertAbsenceRequestListIntoDateEvents(absenceRequests: Array<AbsenceRequest>) {
    return absenceRequests
        .filter((absenceRequest) => absenceRequest.absenceRequestStatus === 'Created')
        .flatMap(convertAbsenceRequestIntoDateEvents);
}

function convertHolidayToDateEvent(holiday: Holiday): CapitechDateEventType {
    return {
        type: 'holiday',
        color: dataVisuals.holiday.color,
        descripiton: holiday.name,
        fillVariant: dataVisuals.holiday.fillVariant,
        sourceData: holiday,
        dateString: holiday.date,
        timeStartAsDate: dateI18n(holiday.date, 'YYYY-MM-DD').toDate(),
        timeStopAsDate: dateI18n(holiday.date, 'YYYY-MM-DD').toDate(),
        titleFromTranslationKey: 'calendar.holiday',
        isWholeDay: true,
        isHoliday: true
    };
}

function convertDutyPlannedToDateEvent(dutyPlanned: DutyPlanned): CapitechDateEventType {
    return {
        type: 'dutyPlanned',
        color: dataVisuals.dutyPlanned.color,
        descripiton: [dutyPlanned.dutyIdString, dutyPlanned.departmentDescription]
            .filter((displayData) => Boolean(displayData))
            .join(' '),
        fillVariant: dataVisuals.dutyPlanned.fillVariant,
        sourceData: dutyPlanned,
        dateString: dutyPlanned.dutyStartDate,
        timeStartAsDate: dateI18n(
            `${dutyPlanned.dutyStartDate} ${dutyPlanned.dutyStartTime}`,
            'YYYY-MM-DD HH:mm'
        ).toDate(),
        timeStopAsDate: dateI18n(
            `${dutyPlanned.dutyEndDate} ${dutyPlanned.dutyEndTime}`,
            'YYYY-MM-DD HH:mm'
        ).toDate(),
        timeStart: dutyPlanned.dutyStartTime,
        isTimeStartAdjusted:
            dutyPlanned.isAdjusted &&
            dutyPlanned.definitionDutyStartTime !== dutyPlanned.dutyStartTime,
        timeStop: dutyPlanned.dutyEndTime,
        isTimeStopAdjusted:
            dutyPlanned.isAdjusted && dutyPlanned.definitionDutyEndTime !== dutyPlanned.dutyEndTime,
        titleFromTranslationKey: 'calendar.dutyPlanned',
        isWholeDay: false,
        isHoliday: false
    };
}

function convertDutyAvailableToDateEvent(dutyAvailable: DutyAvailable): CapitechDateEventType {
    return {
        type: 'dutyAvailable',
        color: dataVisuals.dutyAvailable.color,
        descripiton: [dutyAvailable.dutyStrId, dutyAvailable.departmentDescription]
            .filter((displayData) => Boolean(displayData))
            .join(' '),
        fillVariant: dataVisuals.dutyAvailable.fillVariant,
        sourceData: dutyAvailable,
        dateString: dutyAvailable.startDate,
        timeStartAsDate: dateI18n(
            `${dutyAvailable.startDate} ${dutyAvailable.originalStartTime}`,
            'YYYY-MM-DD HH:mm'
        ).toDate(),
        timeStopAsDate: dateI18n(
            `${dutyAvailable.endDate} ${dutyAvailable.originalEndTime}`,
            'YYYY-MM-DD HH:mm'
        ).toDate(),
        timeStart: dutyAvailable.originalStartTime,
        timeStop: dutyAvailable.originalEndTime,
        titleFromTranslationKey: 'calendar.dutyAvailable',
        isWholeDay: false,
        isHoliday: false
    };
}

function convertDutyRequestedToDateEvent(
    dutyRequested: DutyRequested,
    availableDuties: Array<DutyAvailable>
): CapitechDateEventType {
    const matchingAvailableDuty = availableDuties.find(
        (duty) => duty.dutyId === dutyRequested.dutyId
    );
    const description = matchingAvailableDuty
        ? matchingAvailableDuty.dutyDescriptions
        : dutyRequested.departmentDescription;
    return {
        type: 'dutyRequested',
        color: dataVisuals.dutyRequested.color,
        descripiton: `${dutyRequested.dutyStrId} | ${description}`,
        fillVariant: dataVisuals.dutyRequested.fillVariant,
        sourceData: dutyRequested,
        dateString: dutyRequested.startDate,
        timeStartAsDate: dateI18n(
            `${dutyRequested.startDate} ${dutyRequested.originalStartTime}`,
            'YYYY-MM-DD HH:mm'
        ).toDate(),
        timeStopAsDate: dateI18n(
            `${dutyRequested.endDate} ${dutyRequested.originalEndTime}`,
            'YYYY-MM-DD HH:mm'
        ).toDate(),
        timeStart: dutyRequested.originalStartTime,
        timeStop: dutyRequested.originalEndTime,
        titleFromTranslationKey: 'calendar.dutyRequested',
        isWholeDay: false,
        isHoliday: false
    };
}

function convertDutyWishToDateEvent(dutyWish: DutyWish): CapitechDateEventType {
    return {
        type: 'dutyWish',
        color: dataVisuals.dutyWish.color,
        descripiton: i18n.t('calendar.dutyWish'),
        fillVariant: dataVisuals.dutyWish.fillVariant,
        sourceData: dutyWish,
        dateString: dutyWish.date,
        timeStartAsDate: dateI18n(dutyWish.date, 'YYYY-MM-DD').toDate(),
        timeStopAsDate: dateI18n(dutyWish.date, 'YYYY-MM-DD').toDate(),
        titleFromTranslationKey: 'calendar.dutyWish',
        isWholeDay: true,
        isHoliday: false
    };
}

function convertDutyWishPeriodToMultipleDateEvents(
    dutyWishPeriod: DutyWishPeriod
): Array<CapitechDateEventType> {
    const periodFromDate = dateI18n(dutyWishPeriod.fromDate);
    const periodToDate = dateI18n(dutyWishPeriod.toDate);
    const periodLength = periodToDate.diff(periodFromDate, 'day') + 1;

    const periodLengthSlots = times(periodLength, (index) => {
        const indexDate = periodFromDate.add(index, 'day');
        const includeDate = dutyWishPeriod.requestedDaysOfWeek.includes(indexDate.day());

        return includeDate
            ? {
                  type: 'dutyWishPeriod',
                  color: dataVisuals.dutyWishPeriod.color,
                  descripiton: i18n.t('calendar.dutyWishPeriod'),
                  fillVariant: dataVisuals.dutyWishPeriod.fillVariant,
                  sourceData: dutyWishPeriod,
                  dateString: dateToIsoString(indexDate.toDate()),
                  timeStartAsDate: dateI18n(`${dutyWishPeriod.fromDate}`, 'YYYY-MM-DD').toDate(),
                  timeStopAsDate: dateI18n(`${dutyWishPeriod.toDate}`, 'YYYY-MM-DD').toDate(),
                  titleFromTranslationKey: 'calendar.dutyWishPeriod',
                  isWholeDay: true,
                  isHoliday: false
              }
            : null;
    });

    return periodLengthSlots.filter((slot) => slot !== null) as Array<CapitechDateEventType>;
}

function convertDutyWishPeriodListIntoDateEvents(dutyWishPeriods: Array<DutyWishPeriod>) {
    return dutyWishPeriods.flatMap(convertDutyWishPeriodToMultipleDateEvents);
}

type DateEventGroupIdentifierContent = Pick<CapitechDateEventType, 'type'>;
function toCapitechDateEventGroupIdentifier(dateEvent: CapitechDateEventType): string {
    const jsonData: DateEventGroupIdentifierContent = {
        type: dateEvent.type
    };
    return JSON.stringify(jsonData);
}

function fromCapitechDateEventGroupIdentifier(
    groupKeyName: string
): DateEventGroupIdentifierContent {
    return JSON.parse(groupKeyName);
}

type DateEventSubsetIdentifierContent = Pick<
    CapitechDateEventType,
    'type' | 'fillVariant' | 'titleFromTranslationKey'
>;
function toSubsetIdentifier(dateEvent: CapitechDateEventType): string {
    const jsonData: DateEventSubsetIdentifierContent = {
        type: dateEvent.type,
        fillVariant: dateEvent.fillVariant,
        titleFromTranslationKey: dateEvent.titleFromTranslationKey
    };
    return JSON.stringify(jsonData);
}
function fromSubsetIdentifier(subsetKeyName: string): DateEventSubsetIdentifierContent {
    return JSON.parse(subsetKeyName);
}

function createMetaDataCollectionFromDateEventSubsets(
    dateEvents: Array<CapitechDateEventType>
): Array<SubsetMetaData> {
    const eventSubsetsByIdentifier = groupBy(dateEvents, toSubsetIdentifier);

    const list = map(eventSubsetsByIdentifier, (subsetDateEvents, subsetIdentifier) => {
        const { fillVariant, titleFromTranslationKey, type } =
            fromSubsetIdentifier(subsetIdentifier);

        const dotData: SubsetMetaData = {
            color: dataVisuals[type].color,
            fillVariant,
            count: subsetDateEvents.length,
            titleFromTranslationKey
        };
        return dotData;
    });

    return list;
}

const GrouplessCapitechDateEventKinds: Array<CapitechDataName> = ['holiday'];

function getSortedCapitechDateEventsWithinOneDay(
    events: Array<CapitechDateEventType>
): CapitechDateEventSet {
    const [eventsGroupless, shouldBeGrouped] = partition(events, (event) =>
        GrouplessCapitechDateEventKinds.includes(event.type)
    );

    const eventsGroupedByType = groupBy(shouldBeGrouped, toCapitechDateEventGroupIdentifier);

    const eventGroups = map(eventsGroupedByType, (eventsInSingleType, dateGroupIdentifier) => {
        const groupDataFromIdentifier = fromCapitechDateEventGroupIdentifier(dateGroupIdentifier);

        const set: CapitechDateEventGroupType = {
            items: sortBy(eventsInSingleType, ['timeStartAsDate', 'timeStopAsDate']),
            itemType: groupDataFromIdentifier.type,
            metaDataCollectionFromSubsets:
                createMetaDataCollectionFromDateEventSubsets(eventsInSingleType)
        };

        return set;
    });

    const unsortedSet = [...eventsGroupless, ...eventGroups];

    // AllPermutationsOfSmallUnion<CapitechDataName> would be preferable, CapitechDataName's size causes VSCode to slow down to a crawl
    const dateEventOrder: Array<CapitechDataName> = [
        'holiday',
        'absenceRequest',
        'absence',
        // Duties are sorted according to MC1 - component-calendar.js:150
        'dutyPlanned',
        'time',
        'timeIncomplete',
        'dutyRequested',
        'dutyWish',
        'dutyWishPeriod',
        'dutyAvailable'
    ];

    return sortBy(unsortedSet, (dateEventOrGroup) => {
        const typeId =
            'items' in dateEventOrGroup ? dateEventOrGroup.itemType : dateEventOrGroup.type;

        // The order is decided by a priority number.
        const priorityNumber = dateEventOrder.indexOf(typeId);
        return priorityNumber;
    });
}

type UseDateEventsFromCapitechTimePeriodResult = {
    dateEventSets: CapitechDateEventSetsByDate;
    isLoading: boolean;
};

export default function useDateEventsFromCapitechTimePeriod(): UseDateEventsFromCapitechTimePeriodResult {
    const { timeTransactions, isLoading: isLoadingTimeTransactions } = useTimeTransactionsState();
    const { absenceTransactions, isLoading: isLoadingAbsenceTransctions } =
        useAbsenceTransactions();
    const { holidays } = useHolidays();

    const { fromDate, toDate } = useSelectedTimePeriod();
    const previousFromDate = usePrevious(fromDate);
    const previousToDate = usePrevious(toDate);
    const startsLoadingNextTick = fromDate !== previousFromDate || toDate !== previousToDate;

    const { absenceRequests, isLoading: isLoadingAbsenceRequests } =
        useAbsenceRequestsAffectingDateRange({
            from: fromDate,
            to: toDate
        });
    const memoizedAbsenceRequestEvents = useMemo(
        () => convertAbsenceRequestListIntoDateEvents(absenceRequests),
        [absenceRequests]
    );

    const { isLoading: isLoadingDutiesData, ...duties } = useDutiesInSetDateRange();

    const { isVisibleData } = useCapitechDataVisibility();

    const isLoading =
        startsLoadingNextTick ||
        isLoadingTimeTransactions ||
        isLoadingAbsenceTransctions ||
        isLoadingAbsenceRequests ||
        isLoadingDutiesData;

    const dateEventSets = useMemo<CapitechDateEventSetsByDate>(() => {
        if (isLoading) return {};

        const timeTransactionsCompleteToInclude = isVisibleData('time')
            ? timeTransactions.filter(
                  (timeTransaction) => timeTransaction.timeIn && timeTransaction.timeOut
              )
            : [];
        const timeTransactionsIncompleteToInclude = isVisibleData('timeIncomplete')
            ? timeTransactions.filter(
                  (timeTransaction) => !timeTransaction.timeIn || !timeTransaction.timeOut
              )
            : [];
        const absenceTransactionsToInclude = isVisibleData('absence') ? absenceTransactions : [];
        const memoizedAbsenceRequestEventsToInclude = isVisibleData('absenceRequest')
            ? memoizedAbsenceRequestEvents
            : [];

        const dutiesPlannedToInclude = isVisibleData('dutyPlanned') ? duties.planned.items : [];
        const dutiesAvailableToInclude = isVisibleData('dutyAvailable')
            ? duties.available.items.filter(
                  (duty) => !duties.requested.items.find((r) => r.dutyId === duty.dutyId)
              )
            : [];
        const dutiesRequestedToInclude = isVisibleData('dutyRequested')
            ? duties.requested.items
            : [];

        const dutyWishesToInclude = isVisibleData('dutyWish') ? duties.wish.items : [];

        const dutyWishPeriodsToInclude = isVisibleData('dutyWishPeriod')
            ? duties.wishPeriods.items
            : [];

        const allEvents = [
            ...timeTransactionsCompleteToInclude.map(convertTimeTransactionDetailToDateEvent),
            ...timeTransactionsIncompleteToInclude.map(convertTimeTransactionDetailToDateEvent),
            ...absenceTransactionsToInclude.map(convertAbsenceTransactionToDateEvent),
            ...holidays.map(convertHolidayToDateEvent),
            ...memoizedAbsenceRequestEventsToInclude,
            ...dutiesPlannedToInclude.map(convertDutyPlannedToDateEvent),
            ...dutiesAvailableToInclude.map(convertDutyAvailableToDateEvent),
            ...dutiesRequestedToInclude.map((dutyRequested) =>
                convertDutyRequestedToDateEvent(dutyRequested, duties.available.items)
            ),
            ...dutyWishesToInclude.map(convertDutyWishToDateEvent),
            ...convertDutyWishPeriodListIntoDateEvents(dutyWishPeriodsToInclude)
        ];

        const eventsByDate = groupBy(allEvents, 'dateString');
        const accumulatedEventsByDate = mapValues(
            eventsByDate,
            getSortedCapitechDateEventsWithinOneDay
        );
        return accumulatedEventsByDate;
    }, [
        timeTransactions,
        absenceTransactions,
        holidays,
        memoizedAbsenceRequestEvents,
        duties,
        isVisibleData,
        isLoading
    ]);

    return { dateEventSets, isLoading };
}
